import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import useCommonStyles from "../library/useCommonStyles"
import { WordpressPageQuery } from "../types/schema"

type Props = {
  data: WordpressPageQuery
}

const useStyles = makeStyles({
  gymmanagerIframe: {
    minHeight: "800px",
  },
})

export default function MembershipPage({ data }: Props) {
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  return (
    <Layout>
      <Seo />
      {data?.wpPage && (
        <Container className={commonClasses.pageContainer}>
          <>
            <Typography
              component="h3"
              dangerouslySetInnerHTML={{ __html: data.wpPage.title }}
              variant="h3"
            />
            <Typography
              className={commonClasses.threeFours}
              color="secondary"
              component="div"
              dangerouslySetInnerHTML={{ __html: data.wpPage.content }}
              variant="body1"
            />
          </>
        </Container>
      )}
      <Container>
        <iframe
          className={classes.gymmanagerIframe}
          frameBorder="0"
          scrolling="auto"
          src="https://fitnessmlyn.gymmanager.com.pl/buypass/passes?widget=true"
          title="GymManager passes"
          width="100%"
        />
      </Container>
    </Layout>
  )
}
