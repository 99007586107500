import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import useEnglish from "../library/useEnglish"
import MembershipPage from "../pagesLayouts/MembershipPage"
import { WordpressPageQuery } from "../types/schema"

export default function MembershipEn() {
  useEnglish()

  const query = graphql`
    query {
      wpPage(language: { slug: { eq: "en" } }, slug: { eq: "membership-en" }) {
        ...WordpressPageFragment
      }
    }
  `
  const data = useStaticQuery<WordpressPageQuery>(query)

  return <MembershipPage data={data} />
}
